import React from 'react'
import { useStoreState } from 'easy-peasy'
import { Icon } from 'semantic-ui-react'

import './footer.css'

const Footer = () => {

    const release = process.env.REACT_APP_RELEASE
    const ui = useStoreState(state => state.ui)

    const color = ui.socket_status === 'connected' ? 'green' : 'red'

    return (
        <footer>
          <div className='footer-status'>
            <Icon color={color} name='circle' />
            { ui.socket_status }
          </div>
          <div className="footer-attribution">
            Site developed by Todd Valentic at SRI International. 
          </div>
          <div className="footer-release">
            Release {release} 
          </div>
        </footer>
    )
}

export { Footer }
