import io from 'socket.io-client' 

import { store } from 'store'

const socket_url= process.env.REACT_APP_SOCKETIO_URL
const socket = io(window.location.origin,{'path':socket_url})

export const socketService = {
    socket,
    subscribe_all,
    subscribe_watchdog,
    subscribe_weather,
    subscribe_cameras
}

// Used to debug states

/*
socket.on('connect',() => console.log('connect event')) 
socket.on('connect_timeout',() => console.log('connect_timeout event'))
socket.on('connect_error',() => console.log('connect_error event'))
socket.on('error',() => console.log('error event'))
socket.on('disconnect',() => console.log('disconnect event')) 
socket.on('reconnection_attempt',() => console.log('reconnection_attempt event'))
socket.on('reconnecting',() => console.log('reconnecting event'))
socket.on('reconnect_error',() => console.log('reconnect_error event'))
socket.on('reconnect_failed',() => console.log('reconnect_failed event'))
socket.on('reconnect',() => console.log('reconnect event')) 
socket.on('ping',() => console.log('ping event'))
socket.on('pong',() => console.log('pong event'))
*/

socket.on('connect',() => {
    store.dispatch.ui.update_socket_status('connected')
    })

socket.on('disconnect',() => {
    store.dispatch.ui.update_socket_status('disconnected')
    store.dispatch.weather.reset()
    })

socket.on('reconnect',() => {
    subscribe_all()
    store.dispatch.weather.get_weather_lastday_10min()
    })

socket.on('action',message => {
    console.debug('action',message)

    if (message.type === 'WEBSOCKET_WEATHER_STARTUP') {
        message.payload.map(data => store.dispatch.weather.update(data))
    }

    if (message.type === 'WEBSOCKET_WEATHER_UPDATE') {
        store.dispatch.weather.update(message.payload)
    }

    if (message.type === 'WEBSOCKET_CAMERAS_STARTUP') {
        message.payload.map(data => store.dispatch.cameras.update(data))
    }

    if (message.type === 'WEBSOCKET_CAMERAS_UPDATE') {
        store.dispatch.cameras.update(message.payload)
    }

    if (message.type === 'WEBSOCKET_WATCHDOG_STARTUP') {
        store.dispatch.watchdog.update(message.payload)
    }

    if (message.type === 'WEBSOCKET_CAMERAS_UPDATE') {
        store.dispatch.cameras.update(message.payload)
    }

})

function subscribe_weather() {
    socket.emit('join',{'room':'weather'})
}
 
function subscribe_cameras() {
    socket.emit('join',{'room':'cameras'})
}
      
function subscribe_watchdog() {
    socket.emit('join',{'room':'watchdog'})
}

function subscribe_all() {
    subscribe_watchdog()
    subscribe_weather()
    subscribe_cameras()
}


