import auth     from './auth.model'
import api      from './api.model'
import weather  from './weather.model'
import cameras  from './cameras.model'
import ui       from './ui.model'
import watchdog from './watchdog.model'

const model = {
    auth,
    api,
    weather,
    cameras,
    ui,
    watchdog
}

export default model

