import React from 'react'
import { useStoreState } from 'easy-peasy'
import classNames from 'classnames'

import print from 'support/helpers/print_kit'
import weather_kit from 'support/helpers/weather_kit'
import WindRose from './windrose_react'
import LineChart from './linechart_react'

import './weather.css'

const TemperatureCard = ({devices}) => {

    let temperature_c = null 
    let temperature_min_c = null
    let temperature_max_c = null
    let wind_chill_c = null

    if (devices && 'noaa-tower' in devices) {
        temperature_c = devices['noaa-tower']['temperature_c']
        temperature_min_c = devices['noaa-tower']['temperature_min_c']
        temperature_max_c = devices['noaa-tower']['temperature_max_c']

        const wind_speed_kn = devices['noaa-tower']['wind_speed_kts']

        wind_chill_c = weather_kit.wind_chill_c(temperature_c,wind_speed_kn)
    }

    return <div className="weather-card">
            <div className="card-title">Temperature</div>
            <div className="card-value">{print.as_temp(temperature_c,0)}</div>
            <div className="card-stats">
                <div>Chill:</div>
                <div>{print.as_temp(wind_chill_c)}</div>
                <div>Low:</div>
                <div>{print.as_temp(temperature_min_c)}</div>
                <div>High:</div>
                <div>{print.as_temp(temperature_max_c)}</div>
            </div>
          </div>
}

const WindSpeedCard = ({devices}) => {

    let wind_speed_kts = null 
    let wind_speed_min_kts = null 
    let wind_speed_max_kts = null 

    if (devices && 'noaa-tower' in devices) {
        wind_speed_kts = devices['noaa-tower']['wind_speed_kts']
        wind_speed_min_kts = devices['noaa-tower']['wind_speed_min_kts']
        wind_speed_max_kts = devices['noaa-tower']['wind_speed_max_kts']
    }

    const classes = classNames({
        'weather-card': true,
        'north-wind': wind_speed_kts < 2
        })

    return <div className={classes}>
            <div className="card-title">Wind Speed</div>
            <div className="card-value">{print.as_knots(wind_speed_kts)}</div>
            <div className="card-stats">
                <div>Min:</div>
                <div>{print.as_knots(wind_speed_min_kts)}</div>
                <div>Max:</div>
                <div>{print.as_knots(wind_speed_max_kts)}</div>
            </div>
          </div>
}

const WindDirCard= ({devices}) => {

    let wind_dir_deg = null 
    let wind_speed_kts = null
    let north_wind = false

    if (devices && 'noaa-tower' in devices) {
        wind_dir_deg = devices['noaa-tower']['wind_direction_deg']
        wind_speed_kts = devices['noaa-tower']['wind_speed_kts']
        north_wind = weather_kit.is_north_winds(wind_dir_deg, wind_speed_kts)
    }

    const classes = classNames({ 
        'weather-card': true,
        'north-wind': north_wind
        })

    return <div className={classes} >
            <div className="card-title">Wind&nbsp;Direction</div>
            <div className="card-value">{print.as_degs(wind_dir_deg,0)} {print.as_compass(wind_dir_deg)}</div>
            <div className="card-stats-compass">
            </div>
          </div>
}

const PressureCard= ({devices}) => {

    let pressure_mb = null 
    let pressure_min_mb = null 
    let pressure_max_mb = null 

    if (devices && 'noaa-tower' in devices) {
        pressure_mb = devices['noaa-tower']['pressure_mb']
        pressure_min_mb = devices['noaa-tower']['pressure_min_mb']
        pressure_max_mb = devices['noaa-tower']['pressure_max_mb']
    }

    return <div className="weather-card">
            <div className="card-title">Pressure</div>
            <div className="card-value">{print.as_mb(pressure_mb,0)}</div>
            <div className="card-stats">
                <div>Low:</div>
                <div>{print.as_mb(pressure_min_mb)}</div>
                <div>High:</div>
                <div>{print.as_mb(pressure_max_mb)}</div>
            </div>
          </div>
}

const WeatherCards = ({devices}) => {

    return <div className="weather-cards">
             <TemperatureCard devices={devices} />
             <WindSpeedCard devices={devices} />
             <WindDirCard devices={devices} />
             <PressureCard devices={devices} />
           </div>
}

const TemperaturePlot = (props) => ( 
    <LineChart 
        style_name="temperature-plot" 
        title="Temperature"
        y_key="temperature_c_avg"
        y_domain={[-60,0]}
        y_area_axis={1}
        y_units={"\u00B0C"}
        {...props}
        />
)

const WindSpeedPlot = (props) => ( 
    <LineChart 
        style_name="windspeed-plot" 
        title="Wind Speed"
        y_key="wind_speed_kts_avg"
        y_units=' kn'
        y_domain={[0,40]}
        x_title='Local Time'
        {...props}
        />
)

const WindDirPlot = (props) => ( 
    <LineChart 
        style_name="winddir-plot" 
        title="Wind Direction"
        y_key="wind_direction_deg_avg"
        y_units={'\u00B0'}
        y_domain={[-180,180]}
        y_tick_values={[-180,-90,0,90,180]}
        y_tick_labels={['S','W','N','E','S']}
        {...props}
        />
)


const WindRosePlot = (props) => ( 
    <WindRose
        style_name="windrose-plot" 
        title="24-Hour Wind Distribution"
        y_key="wind_direction_deg_avg"
        y_units=' deg'
        {...props}
        />
)



const WeatherPlots = () => {

    const data = useStoreState(state => state.weather.lastday_10min)

    return  <div className="weather-plots">
                <TemperaturePlot data={data} />
                <WindSpeedPlot data={data} />
                <WindDirPlot data={data} />
                <WindRosePlot data={data} />
            </div>
}

const WeatherPage = () => {

    const devices = useStoreState( state => state.weather.devices)

    return (
        <div className="weather-page">
            <WeatherCards devices={devices} /> 
            <WeatherPlots />
        </div>
    )
}

export { WeatherPage }
