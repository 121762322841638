/********************************************************************\
 *  Weather related utility functions
 *
 *  2020-08-03  Todd Valentic
 *              Initial implementations
 *  
\********************************************************************/

const wind_chill_c = (temp_c,vel_kn) => {
    //  Joint Action Group for Temperature Indices model
    //  https://en.wikipedia.org/wiki/Wind_chill

    const vel_kmph = vel_kn / 0.539956803456  
    const vp = Math.pow(vel_kmph,0.16)

    // Only valid if temp < 10C and ws > 4.8 kmph

    if (temp_c > 10 || vel_kmph < 4.8) {
        return temp_c
    }

    const wind_chill_c = 13.12 + 0.6215*temp_c - 11.37*vp + 0.3965*temp_c*vp

    return wind_chill_c
}

const is_north_winds = (wind_dir_deg, wind_speed_kts) => {

    // 2009-10-27: Definition from A Clarke: between 315 - 45 degrees.
    // 2010-08-23: Redefintion from Katrin between 342 degrees NNW
    //             and 72 degrees ENE owing to move of TAWO
    // 2020-05-10: Redefinition from Nathan Bowker: between 345 and 55
    //             Add quallification = NorthWinds if wind_speed_kts < 2

    wind_dir_deg = (wind_dir_deg+360) % 360

    return wind_dir_deg < 55 || wind_dir_deg > 345 || wind_speed_kts < 2
}

const altimeter_setting_mb = (pressure_mb,altitude_m) => {

    // See support/docs/altimeterSetting.pdf
    
    if (pressure_mb === null || altitude_m === null) {
        return null
    }
    
    const c1 = 0.190284
    const c2 = 0.3

    const int1 = ( (1013.25**c1) * 0.0065) / 288.0
    const int2 = altitude_m / ( ( pressure_mb - 0.3) ** c1 )
    const int3 = 1 + ( int1 * int2 )
    const int4 = int3 ** ( 1/c1 )
    const int5 = ( pressure_mb - c2 ) * int4
                                                
    return int5
}

const pressure_altitude_ft = (pressure_mb) => {
 
    // See support/docs/pressureAltitude.pdf
    
    if (pressure_mb === null) {
        return null
    }
    
    const c1 = 0.190284

    const int1 = pressure_mb / 1013.25
    const int2 = int1 ** c1
    const int3 = (1 - int2) * 145366.45

    return int3
}

export default {
    is_north_winds,
    wind_chill_c,
    altimeter_setting_mb,
    pressure_altitude_ft
}

