
const BASEURL = window.location.origin+process.env.REACT_APP_API_URL

export const apiService = {
    get_weather_lastday,
    get_weather_lastday_10min,
    get_weather_lasthour
}

async function fetch_wrapper(url,options) {
    const response = await fetch(`${BASEURL}`+url,options)
    const json = await response.json()
    return response.ok ? json : Promise.reject(json)
}

function call_api(uri) {
    
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }

    return fetch_wrapper(uri,options)
}

function get_weather_lastday_10min() {
    return call_api('/insite/weather/lastday_10min')
}

function get_weather_lastday() {
    return call_api('/insite/weather/lastday')
}

function get_weather_lasthour() {
    return call_api('/insite/weather/lasthour')
}

