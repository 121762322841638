import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

//import { PrivateRoute, AdminRoute } from 'support/helpers'
import { OverviewPage } from 'pages/overview'
import { WeatherPage } from 'pages/weather'
import { CamerasPage } from 'pages/cameras'
import { MapPage } from 'pages/map'
import { AboutPage } from 'pages/about'

const Routes = () => {

    // React to changes in auth (i.e. logout)
    useStoreState(state => state.auth)
    
    return (
      <Switch>
        <Route exact path="/overview" component={OverviewPage} />
        <Route exact path="/weather" component={WeatherPage} />
        <Route exact path="/cameras" component={CamerasPage} />
        <Route exact path="/map" component={MapPage} />
        <Route exact path="/about" component={AboutPage} />
        <Redirect to="/overview" />
      </Switch>
    )
}

export { Routes }

