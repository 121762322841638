/********************************************************************\
 *  Utility functions for formatted printing
 *
 *  2020-06-23  Todd Valentic
 *              Initial implementation
 *
\********************************************************************/

const as_num = (value,units,decimals) => {
    if (value == null || isNaN(value)) { 
        return '--'
    } else {
        return value.toFixed(decimals)+units
    }
}

// See https://www.alt-codes.net/degree_sign_alt_code.php for degree symbol

const as_temp = (value,decimals) => {
    return as_num(value,'\u00B0C',decimals)
}

const as_temp_c = (value,decimals) => {
    return as_temp(value,decimals) 
}

const as_temp_f = (value,decimals) => {
    return as_num(value,'\u00B0F',decimals)
}

const as_knots = (value,decimals) => {
    return as_num(value,' kn',decimals)
}

const as_degs = (value,decimals) => {
    return as_num(value,'\u00B0',decimals)
}

const as_m = (value,decimals) => {
    return as_num(value,' m',decimals)
}

const as_inhg = (value) => {
    return as_num(value,' in Hg',2)
}

const as_ft = (value,decimals) => {
    return as_num(value,' ft',decimals)
}
const as_compass = (value) => {
    
    const labels = [
        'N','NNE','NE','ENE',
        'E','ESE','SE','SSE',
        'S','SSW','SW','WSW',
        'W','WNW','NW','NNW','N']

    if (value == null) {
        return '--'
    }

    const sector = Math.round((value % 360)/22.5)

    return labels[sector]
}

const as_degs_compass = (value,decimals) => {

    if (value == null) {
        return '--'
    }

    return as_degs(value,decimals)+' '+as_compass(value)
}

const as_mb = (value,decimals) => {
    return as_num(value,' mb',decimals)
}

const as_time = (value) => {
    return value.replace('T',' ').replace('+00:00',' UTC')
}

export default { 
    as_num,
    as_temp,
    as_temp_c,
    as_temp_f,
    as_knots,
    as_degs,
    as_compass,
    as_degs_compass,
    as_mb,
    as_time,
    as_ft,
    as_m,
    as_inhg
}
