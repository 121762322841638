import { action } from 'easy-peasy'
import moment from 'moment-timezone'

const initialState = {
    socket_status: 'disconnected',
    clock: moment().utc()
}

const update_socket_status = action((state,status) => {
    state.socket_status = status
})

const update_clock = action((state) => {
    state.clock = moment().utc() 
})

const actions = {
    update_socket_status,
    update_clock
}

const model = { ...initialState, ...actions }

export default model

