import React from 'react'
import { useStoreState } from 'easy-peasy'
import classNames from 'classnames'

import Clock from './clock_react'
import WindDial from './winddial_react'

import print from 'support/helpers/print_kit'
import weather_kit from 'support/helpers/weather_kit'
import units from 'support/helpers/units'

import './overview.css'

import staticImage from '../cameras/static.jpg'

const ClockTile = props => {

    const timestr = props.data.format('HH:mm')+' '+props.zone
    const classes = classNames(['clock-tile'],props.className)

    return (
        <div className={classes} id={props.id}>
          <div className='clock-tile-title'>{props.title}</div>
          <Clock data={props.data}/>
          <div className='clock-tile-time'>{timestr}</div>
        </div>
    )
}

const TimeTile = () => {

    const clock = useStoreState(state => state.ui.clock) 
    const stationTime = clock.clone().tz('America/Godthab')
    const zone = stationTime.isDST() ? 'WGST' : 'WGT'
    const datestr = stationTime.format('dddd, MMMM D, YYYY')

    return (
        <div className='home-tile home-time-tile'>
          <div className="clocks-tile">
            <ClockTile id="clock-station" data={stationTime} title="Station" zone={zone}/>
            <ClockTile id="clock-utc" data={clock} title="UTC" zone="UTC"/>
          </div>
          <div className='home-time-date'>{datestr}</div>
        </div>
    )
}

const Stat = props => { 

    const classes = classNames({
        'stat': true
        },props.className)

    return (
        <div className={classes}>
            { props.children }
        </div>
        )
}

const StatValue = props => {

    const classes = classNames({
        'stat-value': true,
        'stat-value-large': props.size==='large'
        },props.className)

    return (
        <div className={classes}>
            { props.children }
        </div>
        )
}

const StatLabel = props => (
    <div className="stat-label">
        { props.children }
    </div>
)



const AltitudeTile = ({data}) => {
    
    /* History notes
     *
     * 2010-04-26. Andre Young (SRI)
     *      Jeff Scanniello incorrectly gave the elipsoid elevation of
     *      10,670 ft to the ANG in 2009 and this was just discovered now. 
     *      It is too late for the ANG to approve a change for the 2010 
     *      season and so they are asking we adopt the 10,670 ft number for 
     *      the 2010 season, and then change to 10,530 for the 2011 season.
     *
     * 2013-10-31 Todd Valentic (SRI) 
     *      Changed altitude to 10528 at request of Tracy Sheeley 
     *          (email 10/31/2013 12:43PM).
     *      
     */
    
    const pressure_mb = data
    const altitude_ft = 10528 // geopotential altitude
    const altitude_m  = units.ft_to_m(altitude_ft)

    const pressure_altitude_ft = weather_kit.pressure_altitude_ft(pressure_mb)
    const altimeter_setting_mb = weather_kit.altimeter_setting_mb(pressure_mb,altitude_m)
    const altimeter_setting_in = units.mb_to_inhg(altimeter_setting_mb)

    return (
        <div className='home-weather-tile-altitude'>
            <Stat className="stat-elevation">
                <StatValue>{print.as_ft(altitude_ft)}</StatValue>
                <StatLabel>Elevation</StatLabel>
            </Stat>
            <Stat className="stat-pressure">
                <StatValue>{print.as_mb(pressure_mb)}</StatValue>
                <StatLabel>Pressure</StatLabel>
            </Stat>
            <Stat className="stat-altitude">
                <StatValue>{print.as_ft(pressure_altitude_ft)}</StatValue>
                <StatLabel>Pressure Altitude</StatLabel>
            </Stat>
            <Stat className="stat-altimeter">
                <StatValue>{print.as_inhg(altimeter_setting_in)}</StatValue>
                <StatLabel>Altimeter Setting</StatLabel>
            </Stat>
        </div>
    )
}

const WeatherTile = () => {

    const devices = useStoreState(state => state.weather.devices)

    let temperature_c = null
    //let temperature_f = null
    let wind_speed_kts = null
    let wind_direction_deg = null
    let pressure_mb = null
    let temperature_min_c = null
    let temperature_max_c = null
    let wind_chill_c = null
    let north_wind = false

    if (devices && 'noaa-tower' in devices) {
        const data = devices['noaa-tower']
        temperature_c = data['temperature_c']
        //temperature_f = units.c_to_f(temperature_c)
        temperature_min_c = data['temperature_min_c']
        temperature_max_c = data['temperature_max_c']
        wind_speed_kts = data['wind_speed_kts']
        wind_direction_deg = data['wind_direction_deg']
        pressure_mb = data['pressure_mb']
        wind_chill_c = weather_kit.wind_chill_c(temperature_c,wind_speed_kts)
        north_wind = weather_kit.is_north_winds(wind_direction_deg, wind_speed_kts)
    }
    
    const wind_data = {
        wind_direction_deg,
        wind_speed_kts,
        north_wind
        }

    return (
        <div className='home-tile home-weather-tile'>
            <div className="home-weather-tile-title">
                <div>Current Conditions</div>
            </div>

            <div className="home-weather-tile-temps">   
                <Stat className='stat-temperature'>
                  <StatValue size='large'>{print.as_temp_c(temperature_c)}</StatValue>
                  <StatLabel>Temperature</StatLabel>
                </Stat>

                <Stat className='stat-windchill'>
                    <StatValue>{print.as_temp_c(wind_chill_c)}</StatValue>
                  <StatLabel>Wind Chill</StatLabel>
                </Stat>

                <Stat className='stat-temp-min'>
                  <StatValue>{print.as_temp_c(temperature_min_c)}</StatValue>
                  <StatLabel>Low</StatLabel>
                </Stat>

                <Stat className='state-temp-max'>
                  <StatValue>{print.as_temp_c(temperature_max_c)}</StatValue>
                  <StatLabel>High</StatLabel>
                </Stat>
            </div>

            <div className="home-weather-tile-wind">
                <WindDial data={wind_data} /> 
            </div>

            <AltitudeTile data={pressure_mb} />
        </div>
    )
}

const CameraTile = () => {

    const devices = useStoreState(state => state.cameras.devices)

    // Need to make more robust - combine with camera page

    const BASEURL = 'https://insite.datatransport.org'

    const deviceName = 'smg-netcam'
    const device = devices[deviceName] || {'path':''}
    const url = device.path ? BASEURL+device.path : staticImage

    return (
        <div className='home-tile home-camera-tile'>
          <div className="home-camera-image-wrapper">
            <img className="home-camera-image" src={url} alt={deviceName} />
          </div>
        </div>
    )
}

const OverviewPage = () => {

    return (
        <div className='home-page'>
          <TimeTile />
          <WeatherTile />
          <CameraTile />
        </div>
    )
}

export { OverviewPage }
