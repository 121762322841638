/*************************************************************************
 *
 *  Clock D3 component
 *
 *  Integration of D3 and ReactJS using hooks. Based on the article:
 *
 *      https://medium.com/@stopyransky/react-hooks-and-d3-39be1d900fb
 *
 *  Clock example
 *
 *      http://bl.ocks.org/tomgp/6475678   
 *
 *  2020-06-12  Todd Valentic
 *              Initial implementation
 *
 *************************************************************************/

import * as d3 from 'd3'

import './clock.css'

function ClockD3() {

    var margin              = 50, 
        clockRadius         = 200,
        width               = (clockRadius+margin)*2,
        height              = (clockRadius+margin)*2,
        hourHandLength      = 2*clockRadius/3,
        minuteHandLength    = clockRadius,
        secondHandLength    = clockRadius-12,
        secondHandBalance   = 30,
        secondTickStart     = clockRadius,
        secondTickLength    = -10,
        hourTickStart       = clockRadius,
        hourTickLength      = -18,
        secondLabelRadius   = clockRadius+20,
        secondLabelYOffset  = 8,
        hourLabelRadius     = clockRadius-50,
        hourLabelYOffset    = 14,
        radians             = Math.PI/180,
        svg                 = null

    var hourScale = d3.scaleLinear()
            .range([0,330])
            .domain([0,11])

    var secondScale = d3.scaleLinear()
        .range([0,354])
        .domain([0,59])

    var minuteScale = d3.scaleLinear()
        .range([0,354])
        .domain([0,59])

    var handData = [
        {
            type:       'hour',
            value:      0,
            length:     -hourHandLength,
            scale:      hourScale
        },
        {
            type:       'minute',
            value:      0,
            length:     -minuteHandLength,
            scale:      minuteScale
        },
        {
            type:       'second',
            value:      0,
            length:     -secondHandLength,
            scale:      secondScale,
            balance:    secondHandBalance
        }
    ]

    function updateData(ts) { 
        handData[0].value = (ts.hour() % 12) + ts.minute()/60 
        handData[1].value = ts.minute()
        handData[2].value = ts.second() 
    }

    function chart(selection) {

        selection.each(function(data) {

            if (data) {
                updateData(data)
            }

            svg = d3.select(this).append('svg')
                .attr('viewBox',`${-width/2} ${-height/2} ${width} ${height}`) 
                .attr('width','100%')
                .attr('height','100%')
                .attr('preserveAspectRatio','xMidYMid meet')
                .attr('font-family','Roboto Condensed')
                .attr('class','clock')
                .style('position','absolute')

            var defs = svg.append('defs')

            var faceGradient = defs.append('linearGradient')
                .attr('id','svgGradient')
                .attr('x1','0%')
                .attr('x2','100%')
                .attr('y1','0%')
                .attr('y2','100%')

            faceGradient.append('stop')
                .attr('class','start')
                .attr('offset','0%')
                .attr('stop-color','#929292')
                .attr('stop-opacity',1)

            faceGradient.append('stop')
                .attr('class','end')
                .attr('offset','100%')
                .attr('stop-color','#D6D6D6')
                .attr('stop-opacity',1)

            var face = svg.append('g')
                .attr('class','clock-face')

            face.selectAll('.clock-second-tick')
                .data(d3.range(0,60)).enter()
                    .append('line')
                    .attr('class','clock-second-tick')
                    .attr('x1',0)
                    .attr('x2',0)
                    .attr('y1',secondTickStart)
                    .attr('y2',secondTickStart + secondTickLength)
                    .attr('transform',d => `rotate(${secondScale(d)})`)

            face.selectAll('.clock-second-label')
                .data(d3.range(5,61,5)).enter()
                    .append('text')
                    .attr('class','clock-second-label')
                    .attr('text-anchor','middle')
                    .attr('alignment-baseline','middle')
                    .attr('x',d => secondLabelRadius*Math.sin(secondScale(d)*radians))
                    .attr('y',d => -secondLabelRadius*Math.cos(secondScale(d)*radians)+secondLabelYOffset)
                    .text(d => d)

            face.selectAll('.clock-hour-tick')
                .data(d3.range(0,12)).enter()
                    .append('line')
                    .attr('class','clock-hour-tick')
                    .attr('x1',0)
                    .attr('x2',0)
                    .attr('y1',hourTickStart)
                    .attr('y2',hourTickStart + hourTickLength)
                    .attr('transform',d => `rotate(${hourScale(d)})`)

            face.selectAll('.clock-hour-label')
                .data(d3.range(1,13,1)).enter()
                    .append('text')
                    .attr('class','clock-hour-label')
                    .attr('text-anchor','middle')
                    .attr('alignment-baseline','center')
                    .attr('x',d =>  hourLabelRadius*Math.sin(hourScale(d)*radians))
                    .attr('y',d => -hourLabelRadius*Math.cos(hourScale(d)*radians)+hourLabelYOffset)
                    .text(d => d)
    

            var hands = face.append('g')
                .attr('class','clock-hands')

            face.append('g')
                .attr('class','face-overlay')
                .append('circle')
                    .attr('class','clock-hands-cover')
                    .attr('x',0)
                    .attr('y',0)
                    .attr('r',clockRadius/20)

            hands.selectAll('line')
                .data(handData)
                    .enter()
                    .append('line')
                    .attr('class',d => `clock-${d.type}-hand`)
                    .attr('x1',0)
                    .attr('y1',d => d.balance ? d.balance : 0)
                    .attr('x2',0)
                    .attr('y2',d => d.length)
                    .attr('transform',d => `rotate(${d.scale(d.value)})`)

        })
    }

    chart.moveHands = function(selection) {

        selection.each(function(data) {
            updateData(data)

            svg.select('.clock-hands').selectAll('line')
                .data(handData)
                    .transition()
                    .attr('transform',d => `rotate(${d.scale(d.value)})`)
        })

        return chart
    }

    return chart
}

export default ClockD3

