import { action } from 'easy-peasy'

const initialState = {
    devices: {}
}

const update = action((state,payload) => {
    if (payload.site === 'summitcamp') {
        console.debug('cameras store update',payload)
        state.devices[payload.device] = payload 
    }
})

const actions = {
    update
}

const model = { ...initialState, ...actions }

export default model

