import React from 'react'

import './app.css'

import { Header } from './header'
import { Footer } from './footer'

const App = props => {

    return (
        <>
          <Header/>
            <main>
              { props.children }
            </main>
          <Footer />
        </>
    )
}

export { App }
                
