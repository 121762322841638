import React from 'react'
import {  NavLink } from 'react-router-dom'
import { Responsive, Icon, Menu } from 'semantic-ui-react'

import projectLogo from './SummitMap.png'
//import companyLogo from './NSF_Greyscale_bitmap_Logo.png'
import companyLogo from './NSF_4-Color_bitmap_Logo.png'
//import companyLogo from './arclogof.jpg'

import './header.css'

const MenuLink = ({children, ...props}) =>
    <Menu.Item as={NavLink} {...props}>{ children }</Menu.Item>

const DesktopHeader = () => {
    
    return (
        <header>
          <img className="logo-left" src={projectLogo} alt="Greenland" />
          <div className="center">
            <h1>
              Summit Station, Greenland 
            </h1>

            <Menu inverted pointing borderless secondary compact icon='labeled' size="tiny">
              <MenuLink to="/overview">
                <Icon name='home' />
                Overview
              </MenuLink>

              <MenuLink to="/weather">
                <Icon name='snowflake outline'/>
                Weather
              </MenuLink>

              <MenuLink to="/cameras">
                <Icon name='camera'/>
                Cameras
              </MenuLink>

              <MenuLink to="/map">
                <Icon name='map'/>
                Map
              </MenuLink>

              <MenuLink to="/about">
                <Icon name='info circle'/>
                About
              </MenuLink>

            </Menu>
          </div>
          <img className="logo-right" src={companyLogo} alt="NSF" />
        </header>
    )
}

const MobileHeader = () => {
   
    return (
        <header>
          <img className="logo-left" src={projectLogo} alt="Greenland" />
          <div className="center">
            <h1>Summit Station, Greenland</h1>
            <Menu inverted pointing borderless secondary compact>
                <MenuLink to="/overview" icon='home' />
                <MenuLink to="/weather" icon='snowflake outline' />
                <MenuLink to="/cameras" icon='camera' />
                <MenuLink to="/map" icon='map' />
                <MenuLink to="/about" icon='info circle' />
            </Menu>
          </div>
          <img className="logo-right" src={companyLogo} alt="NSF" />
        </header>
    )
}

const Header = () => {
    return (    
        <>
            <Responsive 
                as={DesktopHeader}
                minWidth={Responsive.onlyTablet.minWidth}
                /> 
            <Responsive 
                as={MobileHeader}
                {...Responsive.onlyMobile}
                /> 
        </>
    )
}

export { Header }
