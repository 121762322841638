import { action, thunk } from 'easy-peasy'

const initialState = {
}

const login = action((state,payload) => {
    state.profile = payload 
})

const logout = action((state,payload) => {
    return {} 
})

const bootstrap = thunk(async (actions, payload) => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
        // Load profile...
    }
})

const actions = {
    login,
    logout,
    bootstrap
}

const model = { ...initialState, ...actions }

export default model

