/********************************************************************\
 *  Units related utility functions
 *
 *  2020-08-05  Todd Valentic
 *              Initial implementations
 *  
\********************************************************************/

function ft_to_m(ft) {
    return ft/3.2808399
}

function m_to_ft(m) {  
    return m*3.2808399
}

function f_to_c(f) {
    return (f-32) * (5/9)
}

function c_to_f(c) {
    return (c*9/5)+32
}

function inhg_to_mb(inhg) {
    return inhg * 33.8637526
}

function mb_to_inhg(mb) {
    return mb * 0.0295301
}

function rad_to_deg(rad) {
    return rad * 180/Math.PI
}

function deg_to_rad(deg) {
    return deg * Math.PI/180
}

function ms_to_knots(ms) {
    return ms/0.5144
}

export default {
    ft_to_m,
    m_to_ft,
    f_to_c,
    c_to_f,
    inhg_to_mb,
    mb_to_inhg,
    rad_to_deg,
    deg_to_rad,
    ms_to_knots
}
