import { action } from 'easy-peasy'

const initialState = {
    timestamp: null,
    checksum: null,
}

const update = action((state,payload) => {

    if (state.checksum && state.checksum !== payload.checksum) {
        window.location.reload(true);
    }

    state.timestamp = payload.timestamp
    state.checksum = payload.checksum
})

const actions = {
    update
}

const model = { ...initialState, ...actions }

export default model

