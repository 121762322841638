import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { Icon } from 'semantic-ui-react'
import classNames from 'classnames'
import moment from 'moment'
import 'moment-timezone'

import './cameras.css'

import staticImage from './static.jpg'

const BASEURL = 'https://insite.datatransport.org'

const Cameras = new Map()
Cameras.set('bighouse-netcam','SMG')
Cameras.set('bighouse-axis2100','SMG ALT')
Cameras.set('smg-netcam','Big House')
Cameras.set('fuelpits-netcam','Flight Line')
Cameras.set('icecaps-netcam','MSF')

const is_stale = (timestamp_str,num,units) => {
    return moment().subtract(num,units) > moment(timestamp_str)
}

const CameraImage = ({ deviceName, ...props }) => {

    const devices = useStoreState(state => state.cameras.devices) 
    const device = devices[deviceName] || {'path':''}
    const url = device.path ? BASEURL+device.path : staticImage
    const stale = device.path ? is_stale(device.timestamp_utc,1,'hours') : false

    const r = stale ? 0 : Math.random()*1000

    const style = { 
        'opacity': device.path ? 
                    stale ? '0.8' : '1' 
                    : '0',
        'transition': `opacity ${r}ms ease-in`,
        }

    return <>
        { stale ? <div className='image-overlay'>Camera Offline</div> : null }
        <img src={url} alt={deviceName} style={style} {...props} /> 
        </>
}

const CameraCardImage = (props) => (
    <CameraImage {...props} className='camera-card-image' />
)

const CameraViewImage = (props) => {
 
    const [ cover, setCover ] = useState(true)

    const toggleCover = (state) => {
        setCover(state)
    }

    const classnames = classNames({
        'camera-view-image': true,
        'cover': cover,
        'contain': !cover
        })

    return (
        <div className="camera-view-image-wrapper"
            style={{ backgroundImage: `url(${staticImage})` }}
            >
            <Icon name={ cover ? "compress" : "expand"}
                 className="camera-view-image-ratio" 
                 />
            <CameraImage {...props} className={classnames} 
                 onClick={() => toggleCover(!cover)}
                />
        </div>
    )

}

const CameraView = (props) => (
    <div className="camera-view">
      <CameraViewImage {...props} /> 
    </div>
)

const CameraCard = ({deviceName,title,selected,onClick}) => {

    const classnames = classNames({
        'camera-card': true,
        'active': deviceName === selected,
        })

    const titleclass = classNames({
        'camera-card-title': true,
        'active': deviceName === selected,
        })

    return <div className={classnames} onClick={e => onClick(deviceName)}>
             <div className={titleclass}>{title}</div>
             <div className='camera-card-image-wrapper'
                style={{ backgroundImage: `url(${staticImage})` }}
                >
                <CameraCardImage deviceName={deviceName} /> 
            </div>
           </div>
}

const CameraCards = ({onClick,selected}) => {

    const cameraNames = [...Cameras.keys()]

    const images = cameraNames.map(name => {
        return <CameraCard 
                key={name} 
                deviceName={name} 
                title={Cameras.get(name)}
                selected={selected} 
                onClick={onClick} />
    })

    return <div className="camera-cards">
            { images } 
           </div>
}

const CamerasPage = () => {
 
    const [ camera, setCamera ] = useState('smg-netcam')

    const selectImage = name => {
        setCamera(name)
    }

    return <div className="camera-page">
             <CameraCards onClick={selectImage} selected={camera}/>
             <CameraView deviceName={camera} />
           </div>
}

export { CamerasPage }
