import { action, thunk } from 'easy-peasy'
import { apiService } from 'services'

const initialState = {
    devices: {},
    lastday: [],
    lastday_10min: []
}

const reset = action((state,payload) => {
    return initialState
})

const update = action((state,payload) => {
    if (payload.site === 'summitcamp') {
        console.debug('weather store update',payload)
        state.devices[payload.device] = payload 
    }
})

const set_weather_lastday_10min = action((state,payload) => {
    state.lastday_10min = payload.filter(entry => entry.device === 'noaa-tower')
})

const get_weather_lastday_10min = thunk(async (actions,payload) => {  
    apiService.get_weather_lastday_10min()
        .then(results => actions.set_weather_lastday_10min(results.weather))
        .catch(err => {
            actions.set_weather_lastday_10min([])
        })
})

const set_weather_lastday = action((state,payload) => {
    state.lastday = payload.filter(entry => entry.device === 'noaa-tower')
})

const get_weather_lastday = thunk(async (actions,payload) => {  
    apiService.get_weather_lastday()
        .then(results => actions.set_weather_lastday(results.weather))
        .catch(err => {
            actions.set_weather_lastday([])
        })
})

const actions = {
    reset,
    update,
    set_weather_lastday,
    get_weather_lastday,
    set_weather_lastday_10min,
    get_weather_lastday_10min
}

const model = { ...initialState, ...actions }

export default model

