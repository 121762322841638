import React from 'react'

import geosummit_image from './geosummit_screenshot.png'
import trackers_image from './trackers_screenshot.png'
import insite_image from './insite_screenshot.png'

import './about.css'

const AboutInfo = () => (
    <div className="info">
    <p>To find more information about Summit Sation and other Arctic Research Operations, visit the new <a href="https://battellearcticgateway.org">Battelle Arctic Gateway</a></p>
    </div>
)

const AboutPage = () => {

    return (
        <div className="about-page">
          <h1>The Conus About Page has Been Decommissioned</h1>
          <AboutInfo />
        </div>
    )
}

export { AboutPage }
