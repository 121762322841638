import React from 'react'
import { render } from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import { BrowserRouter } from 'react-router-dom'

import 'semantic-ui-css/semantic.css'
import 'index.css'
import 'support/assets/random_grey_variations.png'

import { socketService } from 'services'
import { store } from 'store'
import { App } from 'app'
import { Routes } from 'routes'

document.title = process.env.REACT_APP_TITLE

const showApp = () => {
    
    socketService.subscribe_all()

    const second = 1000
    const minute = 60*second
    const weather_rate = 10*minute
    store.dispatch.weather.get_weather_lastday_10min()
    setInterval(store.dispatch.weather.get_weather_lastday_10min,weather_rate)

    const clock_rate = second
    store.dispatch.ui.update_clock()
    setInterval(store.dispatch.ui.update_clock,clock_rate)

    render(
        <StoreProvider store={store}>
          <BrowserRouter basename={process.env.REACT_APP_ROOT_URL}>
            <App>
              <Routes />
            </App>
          </BrowserRouter>
        </StoreProvider>,
        document.getElementById('root')
    )
}

store.dispatch.auth.bootstrap().then(showApp)

