/*************************************************************************
 *
 *  WindRose D3 component
 *
 *  Derived from example at:
 *
 *      https://observablehq.com/@d3/radial-stacked-bar-chart
 *
 *  Integration of D3 and ReactJS using hooks. Based on the article:
 *
 *       https://medium.com/@stopyransky/react-hooks-and-d3-39be1d900fb
 *
 *  2020-06-12  Todd Valentic
 *              Initial implementation
 *
 *************************************************************************/

import * as d3 from 'd3'

const scaleRadial = () => {
    const y = d3.scaleLinear()
    return Object.assign(d => Math.sqrt(y(d)),y)
}

function WindRoseD3() {
        
    const cardinals = ["N","NNE","NE","ENE","E","ESE", "SE","SSE","S","SSW","SW","WSW", "W","WNW","NW","NNW"]

    var margin = 120, 
        width = 975,
        height = 975,
        dirValue = function(d) { return d.wind_direction_deg_avg },
        speedValue = function(d) { return d.wind_speed_kts_avg },
        yScale = scaleRadial(), 
        xScale = d3.scaleBand().range([0,2*Math.PI]).align(0).domain(cardinals),
        zScale = d3.scaleOrdinal(),
        title = '',
        yTitle = '',
        yDomain = [0,1], 
        innerRadius = 100,
        outerRadius = Math.min(width,height)/2-margin,
        maxSpeed = 20,
        speedStep = 5,
        speeds = [],
        numTicks = 5

    const arc = d3.arc()
                .innerRadius(d => yScale(d[0]))
                .outerRadius(d => yScale(d[1]))
                .startAngle(d => xScale(d.data.cardinal) - xScale.bandwidth()/2)
                .endAngle(d => xScale(d.data.cardinal) + xScale.bandwidth()/2)
                .padAngle(0.01)
                .padRadius(innerRadius)

    function transformData(data) {

        const dirStep = 360 / cardinals.length
        const rotate = dirStep/2
        const numSpeeds = Math.ceil(maxSpeed / speedStep) + 1

        speeds = Array.from({length:numSpeeds},(_,k) => 
                    k<(numSpeeds-1) ? 
                    `${k*speedStep} \u2014 ${(k+1)*speedStep} kn` :
                    `${k*speedStep}+ kn`) 

        let total = 0
        let freq = Array.from({length:cardinals.length}, 
                        () => new Array(numSpeeds).fill(0))

        data.forEach((d,k) => {
            const ws = Math.min(speedValue.call(data,d,k),maxSpeed)
            const wd = (((dirValue.call(data,d,k)+360)%360)+rotate)%360
            const dirBin = Math.floor(wd / dirStep)
            const speedBin = Math.floor(ws / speedStep)

            if (!(isNaN(ws) || isNaN(wd))) {
                freq[dirBin][speedBin] += 1
                total += 1
            }
        })

        let results = freq.map((v,k) => { 
            let bins = Object.fromEntries(speeds.map((level,i) => [level,v[i]/total]))
            return { cardinal: cardinals[k], ...bins }
            })

        results.columns = ['cardinal', ...speeds]

        return results 
    }

    function chart(selection) {

        selection.each(function(data) {

            data = transformData(data)

            zScale.domain(speeds).range(d3.schemeBlues[speeds.length])

            yScale.range([innerRadius*innerRadius,outerRadius*outerRadius])
            yScale.domain(yDomain)

            var svg = d3.select(this)
                .append('svg')
                .attr('viewBox',`${-width/2} ${-height/2} ${width} ${height}`) 
                .attr('width','100%')
                .attr('height','100%')
                .attr('preserveAspectRatio','xMidYMid meet')
                .style('position','absolute')
                .style('font-family','Roboto Condensed')

            svg.append('g')
                .call(drawBars,data)

            svg.append('g')
                .call(drawXAxis,data)

            svg.append('g')
                .call(drawYAxis,data)

            svg.append('g')
                .attr('text-anchor','middle')
                .append('text')
                .attr('y',-outerRadius)
                .attr('x',0)
                .attr('dy','-2.25em')
                .attr('fill','#ccc')
                .style('font-size','40px')
                .style('font-family','Roboto Condensed')
                .text(title)

            svg.append('g')
                .call(drawLegend)
        })
    }

    function drawLegend(g) {
        const size = 25
        const x = width/2 - 150 
        const y = -height/2 + 40

        g.selectAll('g')
            .data(speeds)
            .enter()
            .append('rect')
                .attr('x',x)
                .attr('y',(d,i) => y + i*(size+5))
                .attr('width',size)
                .attr('height',size)
                .style('fill',d => zScale(d))

        g.selectAll('g')
            .data(speeds)
            .enter()
            .append('text')
                .attr('x',x + size)
                .attr('dx','0.8em')
                .attr('y',(d,i) => y + i*(size+5) + size/2)
                .attr('fill','#ccc')
                .text(d => d)
                .attr('text-anchor','left')
                .style('alignment-baseline','middle')
                .style('dominant-baseline','middle')
                .style('vertical-align','middle')
                .style('white-space','pre')
                .style('font-size',size+'px')
                .style('font-family','Roboto Condensed')

    }

    function drawBars(g,data) {

        g.selectAll('g')
            .data(d3.stack().keys(data.columns.slice(1))(data))
            .join('g')
                .attr('fill',d => zScale(d.key))
            .selectAll('path')
            .data(d => d)
            .join('path')
                .attr('d',arc)
    }

    function drawXAxis(g,data) {
        g
        .attr('text-anchor','middle')
        .call(g => g.selectAll('g')
            .data(data)
            .join('g')
                .attr('transform',d => `
                    rotate(${(xScale(d.cardinal)*180/Math.PI-90)})
                    translate(${outerRadius},0)
                    `)
                .call(g => g.append('line')
                    .attr('x2',15)
                    .attr('stroke','#FFF')
                    )
                .call(g => g.append('line')
                    .attr('stroke','#FFF')
                    .attr('stroke-width',3)
                    .attr('stroke-opacity',0.1)
                    .attr('x2',-(outerRadius - innerRadius))
                    )
                .call(g => g.append('text')
                    .attr('transform', d => 'rotate(90)translate(0,-35)')
                    .attr('fill','#ccc')
                    .attr('font-size','30px')
                    .text(d => d.cardinal)
                    )
            )
    }

    function drawYAxis(g,data) {

        g
        .attr('text-anchor','middle')
        /*
        .call(g => g.append('text')
            .attr('y',d => -yScale(yScale.ticks(numTicks).pop()))
            .attr('dy','-1em')
            .attr('fill','#fff')
            .style('font-size','25px')
            .text('Frequency')
            )
            */
        .call(g => g.selectAll('g')
            .data(yScale.ticks(numTicks).slice(0))
            .join('g')
                .attr('fill','none')
                .call(g => g.append('circle')
                    .attr('stroke','#fff')
                    .attr('stroke-opacity',0.5)
                    .attr('r',yScale))
                .call(g => g.append('text')
                    .attr('y',d => -yScale(d))
                    .attr('dy','0.35em')
                    .attr('fill','#ccc')
                    .attr('font-size','25px')
                    .text(d => (d*100)+' %')
                )
            )

    }
        
    chart.margin = function(v) {
        if (!arguments.length) return margin
        margin = v
        return chart
    }
 
    chart.width = function(v) {
        if (!arguments.length) return width
        width = v
        return chart
    }
 
    chart.height = function(v) {
        if (!arguments.length) return height
        height = v
        return chart
    }
 
    chart.speed = function(v) {
        if (!arguments.length) return speedValue
        speedValue = v
        return chart
    }
 
    chart.dir = function(v) {
        if (!arguments.length) return dirValue
        dirValue = v
        return chart
    }

    chart.title = function(v) {
        if (!arguments.length) return title
        title = v
        return chart
    }

    chart.yTitle = function(v) {
        if (!arguments.length) return yTitle
        yTitle = v
        return chart
    }

    chart.yDomain = function(v) {
        if (!arguments.length) return yDomain
        if (v === undefined) return chart
        yDomain = v
        return chart
    }

    return chart
}

export default WindRoseD3

