import React, { useEffect, useRef } from 'react'
import classNames from 'classnames' 
import * as d3 from 'd3'

import WindRoseD3 from './windrose_d3'

const ReactComponent = ({data,y_key,...props}) => {

    const refElement = useRef(null)
    const classnames = classNames('plot-wrapper',props.style_name)

    useEffect(initChart,[data,refElement])

    function initChart() {
        const chart = WindRoseD3()
                        .yDomain(props.y_domain)
                        .title(props.title)

        d3.select(refElement.current).select('svg').remove()

        d3.select(refElement.current)
            .data([data])
            .call(chart)
    }

    return (
        <div ref={refElement} className={classnames} {...props} />
    )
}

export default ReactComponent

