import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'

import './map.css'

import sitePhoto from './summit_overhead.jpg'
import siteOverview from './summit_overview_plan-fill.png'
import sitePlan from './summit_site_map-fill.png'

const SitePhoto = () => {

    const [ cover, setCover ] = useState(true)

    const toggleCover = (state) => {
        setCover(state)
    }

    const dragEvent = (e) => {
        console.log('**E',e)
    }

    const style = {'objectFit': cover ? 'cover' : 'contain'}

    return <div className="map-site-photo">
            <div className="map-image-wrapper">
              <Icon name={ cover ? 'compress' : 'expand' }
                    className="map-image-ratio"/>

              <img className="map-image" style={style} 
                    src={sitePhoto} alt="Summit overhead" 
                    onClick={() => toggleCover(!cover)}
                    onDrag={e => dragEvent(e)}
                    /> 
            </div>
           </div>
}

const SitePlans = () => {
    return <div className="map-site-plans">
            <div className="map-site-overview">
              <div className="map-image-wrapper">
                <a href="https://conus.summitcamp.org/data/documents/siteplans/Summit_Overall_Site_Plan_2020.pdf">
                  <img className="map-image" src={siteOverview} alt="Site Overview" />
                </a>
              </div>
            </div>
            <div className="map-site-plan">
              <div className="map-image-wrapper">
                <a href="https://conus.summitcamp.org/data/documents/siteplans/Summit_Station_Site_Map_2020.pdf">
                  <img className="map-image" src={sitePlan} alt="Site Plan" />
                </a>
              </div>
            </div>
           </div>
}

const MapPage = () => {

    return <div className='map-page'>
              <SitePhoto />
              <SitePlans />
           </div>
}

export { MapPage }
