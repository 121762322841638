import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import * as d3 from 'd3'

import ClockD3 from './clock_d3'

const ReactComponent = ({data,y_key,...props}) => {
    
    const refElement = useRef(null)
    const classnames = classNames('plot-wrapper',props.style_name)
    const [chart, setChart] = useState(null)

    useEffect(initChart,[chart,data,refElement])

    function initChart() {
        
        if (!chart) {
            const clock = ClockD3()

            d3.select(refElement.current).select('svg').remove()

            d3.select(refElement.current)   
                .data([data])
                .call(clock)

            setChart(d => clock)
        } else {
            d3.select(refElement.current)
                .data([data])
                .call(chart.moveHands)
        }
    }

    return (
        <div ref={refElement} className={classnames} {...props} />
    )
}

export default ReactComponent

