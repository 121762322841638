/*************************************************************************
 *
 *  Wind direction dial D3 component
 *
 *  Integration of D3 and ReactJS using hooks. Based on the article:
 *
 *      https://medium.com/@stopyransky/react-hooks-and-d3-39be1d900fb
 *
 *  2020-08-04  Todd Valentic
 *              Initial implementation
 *
 *************************************************************************/

import * as d3 from 'd3'
import print from 'support/helpers/print_kit'
import classNames from 'classnames'

import './winddial.css'

function WindDialD3() {

    var svg                 = null, 
        margin              = 50, 
        innerRadius         = 350,
        outerRadius         = 400,
        width               = (outerRadius+margin)*2,
        height              = (outerRadius+margin)*2,
        markerWidth         = 10

    const radians = Math.PI/180

    const faceArc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius)
            .startAngle(0)
            .endAngle(2*Math.PI)

    const markerArc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius)
            .cornerRadius(30)
            .startAngle(-markerWidth*radians)
            .endAngle(markerWidth*radians)

    const markerPointer = d3.symbol()
            .type(d3.symbolTriangle)
            .size(3000)

    var windData = [
        {
            direction:  0,
            speed:      0,
            north_wind: false,
        }
    ]

    function updateData(data) { 
        windData[0].direction = data.wind_direction_deg 
        windData[0].speed = data.wind_speed_kts
        windData[0].north_wind = data.north_wind
    }

    function chart(selection) {

        selection.each(function(data) {

            if (data) {
                updateData(data)
            }

            svg = d3.select(this).append('svg')
                .attr('viewBox',`${-width/2} ${-height/2} ${width} ${height}`) 
                .attr('width','100%')
                .attr('height','100%')
                .attr('preserveAspectRatio','xMidYMid meet')
                .attr('font-family','Roboto Condensed')
                .attr('class','windial')
                .style('position','absolute')

            const face = svg.append('g')
                .attr('class','winddial-face')

            face.append('path')
                .attr('d',faceArc)

            const marker = svg.append('g')
                .attr('class','winddial-marker')

            marker.append('path')
                .attr('d',markerArc)

            marker.append('path')
                .attr('d',markerPointer)
                .attr('transform',`translate(0,${-outerRadius})`)

            var label = svg.append('g')
                .attr('class','winddial-label')
                .attr('text-anchor','middle')

            label.append('text')
                .attr('class','winddial-label-dir')
                .attr('dy',0)
                .text('--')

            label.append('text')
                .attr('class','winddial-label-speed')
                .attr('dy',200)
                .text('--')
                
        })
    }

    chart.update = function(selection) {
        selection.each(data => {
            updateData(data)

            const face_classes = classNames({
                'north-wind': data.north_wind
                })

            svg.select('g.winddial-face').select('path')
                .attr('class',face_classes)

            svg.select('.winddial-label-dir')
                .text(print.as_degs_compass(windData[0].direction))

            svg.select('.winddial-label-speed')
                .text(print.as_knots(windData[0].speed))

            svg.select('g.winddial-marker')
                .data(windData)
                    .transition()
                    .attr('transform',d => `rotate(${d.direction || 0})`)
        })

        return chart

    }

    return chart
}

export default WindDialD3

